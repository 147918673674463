/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React, {useEffect, useState, useContext, useCallback, useRef, Fragment } from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { useLocation } from 'react-router-dom';
import { useWeb3React } from "@web3-react/core";

import Web3 from 'web3';
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";

//import truncateEthAddress from 'truncate-eth-address';
import { Card, Stat, ProgressBar, Chart,  Button, Image, Row, Link, Form, Search, SocialShare, DashboardNav, 
  Message, Grid, Animate, Feedback, useAPI, ViewContext, AuthContext, WalletConnect } from 'components/lib';

import Style from './shop.tailwind.js';
import { ethers, utils, BigNumber } from 'ethers';

//https://eth-mainnet.g.alchemy.com/v2/RR7i6KryXLb4CRj-wkmv4Tyr_lr-9jj0
  
import Axios from 'axios';
//import Style from './project.tailwind.js';

//let lastSearch = '';

export function ShopItemView(props){

  
  //const user = useAPI('/api/user');
  const mintForm = useRef();
  const viewContext = useContext(ViewContext);
  const authContext = useContext(AuthContext);
  
  const context = useContext(AuthContext);
  const location = useLocation();
  const [tokenPrice, setTokenPrice] = useState(0);
  const [tokenWLPrice, setTokenWLPrice] = useState(null);
  const [userWhitelisted, setUserWhitelisted] = useState(false);
  const [userWhiteClaimed, setUserWhitelistClaimed] = useState(false);
  const [tokenChain, setTokenChain] = useState(null);
  const [contractABI, setContractABI] = useState(null);
  const [contractAddress, setContractAddress] = useState(null);
  
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [lastTxHash, setLastTxHash] = useState(null);
  const [waitMint, setWaitMint] = useState(false);
  const [maxMint, setMaxMint] = useState(1);
  const [maxSupply, setMaxSupply] = useState('--');
  const [numMinted, setNumMinted] = useState('--');

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [url, setURL] = useState("");

  const { active, account, chainId } = useWeb3React();
  const [advanced, setAdvanced] = useState(false);
  
  const [chosenMintAmount, setChosenMintAmount] = useState(null);
  const [chosenMintQuantity, setChosenMintQuantity] = useState(1);
  const [referralURL, setReferralURL] = useState(null);
  const [shopReferrer, setShopReferrer] = useState(null);
  

  let pathSplit = location.pathname.split("/");
  const item_id = pathSplit[pathSplit.length-1];
  const user = useAPI("/api/user", authContext);
  const projectInfo = useAPI('/api/project/' + item_id);

  const urlParams = new URLSearchParams(window.location.search);
  let shop_referrer_param = urlParams.get('s');

  function truncateToken(tokenString){
    let retString = tokenString;
    if(tokenString.length > 16){
      let strLen = tokenString.length;
      let string1 = tokenString.substring(0,12);
      let string2 = tokenString.slice(-4);
      retString = string1 + "..." + string2;
    }
    return retString;
  }

  function truncateString(theString, truncLength=50){
    let retString = theString;
    if(theString.length > truncLength){
      let strLen = theString.length;
      let string1 = theString.substring(0,truncLength);
      retString = string1 + "...";
    }
    return retString;
  }

  useEffect(() => {
    let saved_shop_referrer = localStorage.getItem("shop_referrer");
    if(saved_shop_referrer){
      
      console.log("Restoring Shop Referrer");
      console.log(saved_shop_referrer);
      setShopReferrer(saved_shop_referrer);
    }
    
    if(shop_referrer_param){
      localStorage.setItem('shop_referrer', shop_referrer_param);
      console.log("Saving Shop Referrer");
      setShopReferrer(shop_referrer_param);
    }
  },[])

  useEffect(() => {
    //console.log(viewContext);
      setAdvanced(viewContext.isAdvanced);
  }, [viewContext]);  

  useEffect(() => {

    let serverURL = window.location.hostname; //(process.env.SERVER_URL !== undefined && process.env.SERVER_URL !== "") ? process.env.SERVER_URL : settings[process.env.NODE_ENV].server_url;
    if (serverURL.includes("localhost")) {
      serverURL = "http://" + serverURL + ":3000";
    } else {
      //serverURL = "https://" + serverURL;
      serverURL = "https://app.nftscribe.io";
    }
    
    let referURL = serverURL + "/shop/" + item_id;
    if (user.data) {
      console.log(user);
      setReferralURL(referURL + "?s=" + user.data.referral_code);
    } else {
      setReferralURL(null)
    }

  }, [user]);


  useEffect(() => {
    const init = async () => {
      if(projectInfo && projectInfo.data && projectInfo.data.project.contract_abi){
        setTokenPrice(projectInfo.data.project.mint_cost);

        let theABI = JSON.parse(projectInfo.data.project.contract_abi);
        let theContractAddress = projectInfo.data.project.contract_address;
        let theMaxMint = projectInfo.data.project.max_mint;

        setTokenChain(projectInfo.data.project.blockchain);

        let mintNumber = 1;
        setChosenMintQuantity(mintNumber.toString());
        setChosenMintAmount((mintNumber * projectInfo.data.project.mint_cost).toString());

        setContractABI(theABI);
        setContractAddress(theContractAddress);
        setMaxMint(theMaxMint);
        getMintSupply(theContractAddress, theABI);

        try {
          const {ethereum} = window;
          const provider = new ethers.providers.Web3Provider(ethereum);
          const nftContract = new ethers.Contract(theContractAddress, theABI, provider);
          let nftCost = await nftContract.cost();
          let nftCostDecimal = nftCost / 1e18;

          if(projectInfo.data.project.whitelist_mint_cost){
            let nftCostWL = await nftContract.cost_wl();
            let nftCostWLDecimal = nftCostWL / 1e18;
            setTokenWLPrice(nftCostWLDecimal);

            if(account){
              let onWhiteList = await nftContract.checkWhiteList(account);
              if(onWhiteList === "IN_WHITELIST"){
                setUserWhitelisted(true);
                let whiteListClaimed = await nftContract.whitelistClaimed(account);
                if(whiteListClaimed){
                  setUserWhitelistClaimed(true);
                }
              }
            }
          }
          setTokenPrice(nftCostDecimal);
        } catch (error) {
          console.error("Error in contract initialization:", error);
        }
      }
    };

    init();

    // Cleanup function
    return () => {
      // Cancel any pending state updates
      setTokenPrice(0);
      setTokenWLPrice(null);
      setUserWhitelisted(false);
      setUserWhitelistClaimed(false);
      setTokenChain(null);
      setContractABI(null);
      setContractAddress(null);
    };
  }, [projectInfo, account]);

  const getMintSupply = async(theContractAddress, theABI) => {
    try {
      const {ethereum} = window;
      if(ethereum){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const nftContract = new ethers.Contract(theContractAddress, theABI, signer);
        let totalMinted = await nftContract.totalSupply();
        let maxSupply = await nftContract.maxSupply();
        //let maxSupplyBN = new BigNumber(maxSupply);

        //console.log("Total Minted");
        //console.log(totalMinted.toNumber());
        //console.log("Max Supply");
        //console.log(maxSupply.toNumber());
        setNumMinted(totalMinted.toNumber());
        setMaxSupply(maxSupply.toNumber());
      }
    } catch (err) {
      console.log(err);
    }
  }

  const mintNFTHandler = async (mintAmount) => {
    try {
      const {ethereum} = window;
      if(ethereum){
        //console.log(chainName() + "  " + tokenChain);

        
        
        if(chainName() !== tokenChain ){
          return viewContext.modal.show({
            title: chainName() === '' ? 'Connect your ETH Wallet' : 'Incorrect Blockchain',
            buttonText: 'OK',
            destructive: false,
            text: "This NFT only mints on the " + tokenChain.toUpperCase() + " blockchain. Please switch your wallet chain(network).",
        
          }, (res) => {
            
          });

        }

        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const nftContract = new ethers.Contract(contractAddress, contractABI, signer);

        //console.log(tokenPrice);
        //let mintAmount = 1;
        const tokenPrice1 = utils.parseEther(tokenPrice.toString());
        //console.log("tokenPrice1");
        //console.log(tokenPrice1);
        let  totalMintCost = tokenPrice1.mul(mintAmount);
        //console.log(totalMintCost);
        

        const gasPrice = await provider.getGasPrice();
        //console.log(gasPrice);
        //console.log(gasPrice.toString());
             //Estimate gas limit
        //const gasLimit = await nftContract.estimateGas["mint(uint256)"](mintAmount, { gasPrice });
        //console.log(gasLimit);
        //console.log(gasLimit.toString());
        

        //console.log(projectInfo.data.project);
        //console.log("ShopReferrer: " + shopReferrer);
        let projInfo = projectInfo.data.project;
        
        //console.log(resMintSave);
        let txHash = "test";

        /*const resMintSave = await Axios.post('/api/shop/mint', { 
          item_name: projInfo.project_name,
          mint_quantity: mintAmount,
          mint_price: projInfo.mint_cost,
          tx_hash: txHash,
          contract_address: projInfo.contract_address,
          blockchain: projInfo.blockchain,
          referrer: shopReferrer,
          minting_wallet: walletAddress,
          //affiliate_user_id: data.something,
          minting_user_id: user?.data?.id,
          //affiliate_commission: data.something,
          project_id: projectInfo.data.project.id
          }
        );

        return;*/
        


        const transactionOptions = {
          //gasLimit: 6000000,
          gasLimit: 1500000,
          gasPrice: gasPrice, //ethers.utils.parseUnits('5.0', 'gwei'),
          //value: ethers.utils.parseEther('0.02')
          value : totalMintCost
        }

        //let mintPrice = { value: ethers.utils.parseEther("0.01")};
        //console.log("MintPrice:" + mintPrice);
        //console.log(transactionOptions);

        
        //amount, {value: this.state.tokenPrice.mul(mintAmount)}
        try {
          //setWaitMint(true);
          setStatus("Minting... please wait");
          let nftTxn = await nftContract.mint(mintAmount, transactionOptions);
          //console.log("Minting... please wait");
          //console.log(nftTxn);
          
          await nftTxn.wait();
          //console.log(nftTxn);
          txHash = nftTxn.hash;
          

          const resMintSave = await Axios.post('/api/shop/mint', { 
            item_name: projInfo.project_name,
            mint_quantity: mintAmount,
            mint_price: projInfo.mint_cost,
            tx_hash: txHash,
            contract_address: projInfo.contract_address,
            blockchain: projInfo.blockchain,
            referrer: shopReferrer,
            minting_wallet: walletAddress,
            //affiliate_user_id: data.something,
            minting_user_id: user?.data?.id,
            //affiliate_commission: data.something,
            project_id: projectInfo.data.project.id
            }
          );

          const resTokens = await Axios.post('/api/shop/getTokensFromTransaction', { 
            txHash: txHash,
            reveal: true,
            project_id: projectInfo.data.project.id,
            blockchain: projInfo.blockchain,
            }
          );
          //let theUserLevels = resLevels.data.data;
          console.log(resTokens.data.data);
          if(resTokens.data.message){
            viewContext.notification.show(resTokens.data.message, 'success', true);
          }

          /*const receipt = await provider.getTransactionReceipt(txHash);
          console.log(receipt);
          let topic = null;
          let tokens = [];
          if(receipt.logs){
            receipt.logs.forEach(log => {
              let topic = log.topics[3];
              let tokenID = Web3.utils.hexToNumber(topic);
              tokens.push(tokenID);
            });
          }
          console.log(tokens);
          */
          setLastTxHash(txHash);


          


          
          return {
            success: true,
            status:
              "✅ Check out your transaction on " + chainScan() + txHash,
            tokens: resTokens.data.data
          };
        } catch (error) {
          return {
            success: false,
            status: "😥 Something went wrong: " + error.message,
            tokens: []
          };
        }
      } else {
        console.log("Ethereum obj does not exist");
      }

    } catch (err) {
      console.log(err);
    }
  }

  const mintWLNFTHandler = async (mintAmount) => {
    try {
      const {ethereum} = window;
      if(ethereum){
        //console.log(chainName() + "  " + tokenChain);

        
        
        if(chainName() !== tokenChain ){
          return viewContext.modal.show({
            title: chainName() === '' ? 'Connect your ETH Wallet' : 'Incorrect Blockchain',
            buttonText: 'OK',
            destructive: false,
            text: "This NFT only mints on the " + tokenChain.toUpperCase() + " blockchain. Please switch your wallet chain(network).",
        
          }, (res) => {
            
          });

        }

        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const nftContract = new ethers.Contract(contractAddress, contractABI, signer);

        //console.log(tokenWLPrice);
        //let mintAmount = 1;
        const tokenPriceWL1 = utils.parseEther(tokenWLPrice.toString());
        //console.log("tokenPriceWL1");
        //console.log(tokenPriceWL1);
        let totalMintCost = tokenPriceWL1.mul(mintAmount);
        //console.log(totalMintCost);

        const gasPrice = await provider.getGasPrice();
        //console.log(gasPrice);
        //console.log(gasPrice.toString());
             //Estimate gas limit
        //const gasLimit = await nftContract.estimateGas["mint(uint256)"](mintAmount, { gasPrice });
        //console.log(gasLimit);
        //console.log(gasLimit.toString());

        const transactionOptions = {
          //gasLimit: 6000000,
          gasLimit: 1500000,
          gasPrice: gasPrice, //ethers.utils.parseUnits('5.0', 'gwei'),
          //value: ethers.utils.parseEther('0.02')
          value : totalMintCost
        }

        //let mintPrice = { value: ethers.utils.parseEther("0.01")};
        //console.log("MintPrice:" + mintPrice);
        //console.log(transactionOptions);

        
        //amount, {value: this.state.tokenPrice.mul(mintAmount)}
        try {
          //setWaitMint(true);
          setStatus("Minting Whitelist... please wait");
          let nftTxn = await nftContract.whitelistMint(mintAmount, transactionOptions);
          //console.log("Minting... please wait");
          //console.log(nftTxn);
          
          await nftTxn.wait();
          console.log(nftTxn);
          let txHash = nftTxn.hash;
          //let txHash = '0x7e4fb93ee06dc2fd5e31aaa9e9ad120c69da3adac42f9635d480cb5989070e82'; //test

          let projInfo = projectInfo.data.project;
          const resMintSave = await Axios.post('/api/shop/mint', { 
            item_name: projInfo.project_name,
            mint_quantity: mintAmount,
            mint_price: projInfo.mint_cost,
            tx_hash: txHash,
            contract_address: projInfo.contract_address,
            blockchain: projInfo.blockchain,
            referrer: shopReferrer,
            minting_wallet: walletAddress,
            //affiliate_user_id: data.something,
            minting_user_id: user?.data?.id,
            //affiliate_commission: data.something,
            project_id: projectInfo.data.project.id
          });
          

          const resTokens = await Axios.post('/api/shop/getTokensFromTransaction', { 
            txHash: txHash,
            reveal: true,
            project_id: projectInfo.data.project.id,
            blockchain: projInfo.blockchain,
            }
          );
          //let theUserLevels = resLevels.data.data;
          console.log(resTokens.data.data);
          if(resTokens.data.message){
            viewContext.notification.show(resTokens.data.message, 'success', true);
          }

          /*const receipt = await provider.getTransactionReceipt(txHash);
          console.log(receipt);
          let topic = null;
          let tokens = [];
          if(receipt.logs){
            receipt.logs.forEach(log => {
              let topic = log.topics[3];
              let tokenID = Web3.utils.hexToNumber(topic);
              tokens.push(tokenID);
            });
          }
          console.log(tokens);
          */
          setLastTxHash(txHash);
          


          
          return {
            success: true,
            status:
              "✅ Check out your transaction on " + chainScan() + txHash,
            tokens: resTokens.data.data
          };
        } catch (error) {
          return {
            success: false,
            status: "😥 Something went wrong: " + error.message,
            tokens: []
          };
        }
      } else {
        console.log("Ethereum obj does not exist");
      }

    } catch (err) {
      console.log(err);
    }
  }

  async function loadContract() {
    //return new web3.eth.Contract(contractABI, contractAddress);
  }

  function chainName(){
    let theChain = null;
    let theChainName = 'ETH Wallet Required';
    let theScan = 'Etherscan: https://etherscan.io/tx/';
    //console.log("chainID: " + chainId);
    switch(chainId){
      case 1: theChain = 'eth'; theChainName = 'Ethereum Mainnet'; theScan= 'Etherscan: https://etherscan.io/tx/';  break;
      case 3: theChain = 'eth'; theChainName = 'Ropsten Test'; break;
      case 4: theChain = 'eth'; theChainName = 'Rinkeby Test'; break;
      case 5: theChain = 'eth'; theChainName = 'Goerli Test'; break;
      case 42: theChain = 'eth'; theChainName = 'Kovan Test'; break;
      case 56: theChain = 'bnb'; theChainName = 'Binance Smart Chain'; break;
      case 137: theChain = 'matic'; theChainName = 'Polygon Mainnet'; break;
      case 25: theChain = 'cro'; theChainName = 'Cronos Mainnet'; break;
      case 100: theChain = 'xdai'; theChainName = 'Gnosis'; break;
      case 43114: theChain = 'avax'; theChainName = 'Avalanche C'; break;
      case 1666600000: theChain = 'harmony'; theChainName = 'Harmony Mainnet'; break;
      default: theChain = 'eth'; theChainName = null;
    }
    //console.log(theChain);
    return theChainName;
  }

  function chainScan(){
    
    let chainScan = 'Etherscan: https://etherscan.io/tx/';
    let theChain = null;
    let theChainName = 'ETH Wallet Required';
    //console.log("chainID: " + chainId);
    /*
case 'ETH': contractAction = 'https://etherscan.io/address/' + anItem.address; break;
            case 'SOL': contractAction = 'https://solana.nftscan.com/' + anItem.address; break;
            case 'AVAX': contractAction = 'https://snowtrace.io/address/' + anItem.address; break;
            case 'MATIC': contractAction = 'https://polygonscan.com/address/' + anItem.address; break;
            case 'CRO': contractAction = 'https://cronoscan.com/address/' + anItem.address; break;
    */
    switch(chainId){
      case 1: theChain = 'eth'; theChainName = 'Ethereum Mainnet'; chainScan= 'Etherscan: https://etherscan.io/tx/';  break;
      case 3: theChain = 'eth'; theChainName = 'Ropsten Test'; chainScan= 'Etherscan: https://etherscan.io/tx/';  break;
      case 4: theChain = 'eth'; theChainName = 'Rinkeby Test';chainScan= 'Etherscan: https://etherscan.io/tx/';  break;
      case 5: theChain = 'eth'; theChainName = 'Goerli Test'; chainScan= 'Etherscan: https://etherscan.io/tx/';  break;
      case 42: theChain = 'eth'; theChainName = 'Kovan Test'; chainScan= 'Etherscan: https://etherscan.io/tx/';  break;
      case 56: theChain = 'bnb'; theChainName = 'Binance Smart Chain'; chainScan= 'Etherscan: https://etherscan.io/tx/';  break;
      case 137: theChain = 'matic'; theChainName = 'Polygon Mainnet'; chainScan= 'Polyscan: https://polygonscan.com/tx/';  break;
      case 25: theChain = 'cro'; theChainName = 'Cronos Mainnet'; chainScan= 'Cronoscan: https://cronoscan.com/address/';  break;
      case 100: theChain = 'xdai'; theChainName = 'Gnosis'; chainScan= 'Etherscan: https://etherscan.io/tx/';  break;
      case 43114: theChain = 'avax'; theChainName = 'Avalanche C'; chainScan= 'Snowtrace: https://snowtrace.io/address/';  break;
      case 1666600000: theChain = 'harmony'; theChainName = 'Harmony Mainnet'; chainScan= 'Harmony: https://explorer.harmony.one/tx/';   break;
      default: theChain = 'eth'; theChainName = null; chainScan= 'Etherscan: https://etherscan.io/tx/';
    }
    //console.log(theChain);
    return chainScan;
  }

  const onMintPressed = async (mintAmount, referralCode) => {
    
    
    if(referralCode){
      console.log(referralCode);
      setShopReferrer(referralCode);
      localStorage.setItem('shop_referrer', referralCode);
    }

    const { success, status, tokens } = await mintNFTHandler(mintAmount);
    if(success){
      setStatus("Tokens Minted: " + tokens + ". " + status);
    } else {
      setStatus(status);
    }
    
    
    setWaitMint(false);
    if (success) {
      console.log("SUCCESS");
    }
  };

  const onMintWLPressed = async (mintAmount, referralCode) => {
    
    if(referralCode){
      console.log(referralCode);
      setShopReferrer(referralCode);
      localStorage.setItem('shop_referrer', referralCode);
    }

    const { success, status, tokens } = await mintWLNFTHandler(mintAmount);
    if(success){
      setStatus("Tokens Minted: " + tokens + ". " + status);
    } else {
      setStatus(status);
    }
    
    
    setWaitMint(false);
    if (success) {
      console.log("SUCCESS");
    }
  };

  const beTheChange = async(e) => {
    //console.log(e);
    if(e.input === 'mintAmount'){
      let mintNumber = e.value;
      setChosenMintQuantity(mintNumber.toString());
      setChosenMintAmount((mintNumber * tokenPrice).toString());
    }
    /*if(e.input === 'referralCode'){
      let theReferralCode = e.value;
      setShopReferrer(theReferralCode);
    }*/
  }

  const onWalletConnect = async(e) => {
    //console.log(e);
    setWallet(e);
  }

  return (
    <Fragment>
      {user && user.data && 
      <DashboardNav user={user && user.data} center={!advanced} advanced={advanced}/>
      }
    
    <div className={advanced? 'max-w-full' : 'max-w-5xl content-center m-auto'}>
    <Animate type='pop'>

      <Card dark center>
        <Grid cols={referralURL ? "3" : "2"}>
          
          <div className='float-left'>
            <Button
              tiny
              key='centerButton'
              color={ props.destructive ? 'red' : 'green' }
              text={ "Back" }
              goto={-1}
              //action={ () => navigate(-1) }
            />
          </div>
          {referralURL && 
            <div className='float-right text-center text-xs'>
              <Grid cols="2">
                <Button
                  tiny
                  key='centerButton2'
                  color={ props.destructive ? 'red' : 'blue' }
                  text={ "Affiliate Info" }
                  url={'https://wiki.goscribe.io/scribe/partners-and-affiliates/affiliate-program'}
                  target={'_blank'}
                />
                <Button
                  tiny
                  key='centerButton3'
                  color={ props.destructive ? 'red' : 'blue' }
                  text={ "Copy Affiliate Link" }
                  action={ () => navigator.clipboard.writeText(referralURL) }
                />
              </Grid>
            </div>
          }
          <div >
            <SocialShare 
                url={ referralURL }
                description={'The Scribe Shop has NFTs which boost your XP, make games more fun, and give you a blockchain identity!'}
              />
          </div>
        </Grid> 
      </Card>

      
      <Row small >
      

      {!projectInfo.loading && projectInfo.data && projectInfo.data.project &&
        <Card shadow rounded >
          <Grid cols='2'>
          <div>
              <strong>{projectInfo.data.project.project_name}</strong><br/>
              <strong>Minted: {numMinted} of {maxSupply}</strong><br/>

              <strong>Mint Price: </strong>{tokenPrice=== '0' ? 'FREE' : tokenPrice + projectInfo.data.project.cost_label}<br/>

              {tokenWLPrice && <><strong>Whitelist Mint Price: </strong>{tokenWLPrice=== '0' ? 'FREE' : tokenWLPrice + projectInfo.data.project.cost_label}<br/></>}
              {userWhitelisted && !userWhiteClaimed && <><strong>Wallet is WhiteListed!</strong><br/></>}
              {userWhitelisted && userWhiteClaimed && <><strong>Wallet is WhiteListed, already claimed!</strong><br/></>}
              {projectInfo.data.project.collection_page && 
                <p>
                  <Button
                    small
                    id='centerButton'
                    key='centerButton'
                    color={ props.destructive ? 'red' : 'blue' }
                    text={ "OpenSea Listing" }
                    url={projectInfo.data.project.collection_page}
                    target={'_blank'}
                    //action={ () => navigate(-1) }
                  />
                </p>
              }
              

              <span>{projectInfo.data.project.token_description}</span><br/><br/>
            </div>
            <div>
              <Image
                className={ Style.nftImagePreview }
                nftImage = {true}
                border = {true}
                source={ projectInfo.data.project.preview_image}
              />
            </div>
            

          </Grid>
          <span>{projectInfo.data.project.token_instructions}</span>
        </Card>
      }


      <Card shadow rounded >
        {tokenChain && <><span>{chainName()} {chainName()!==tokenChain && chainName() != null && <><br/><strong>&nbsp;Incorrect Network, please use {tokenChain.toUpperCase()}</strong></>}</span><br/></>}
        <WalletConnect button={true} onConnect={onWalletConnect} /><br/>
        {projectInfo.data && 
        
          <>
          {(!userWhitelisted || userWhiteClaimed) && 
          <>
            
            <Form 
              data={{ 
                referralCode: {
                  label: `Referral Code`,
                  type: 'text',
                  value: shopReferrer,
                },
                mintAmount: {
                  label: `Number to Mint (Maximum ${projectInfo.data.project.max_mint})`,
                  type: 'number',
                  value: chosenMintQuantity,
                  min: 1,
                  max: maxMint,
                  required: true,
                  
                  errorMessage: `Please enter a number between 1 and ${projectInfo.data.project.max_mint}`
                },
              }}
              restrictWidth
              updateOnChange
              onChange={ e => {
                beTheChange(e)
              }}
              buttonText={ 'Mint' }
              callback={ res => {
                //console.log(res);
                onMintPressed(res.mintAmount, res.referralCode);
              }}
              
            
            />
            </>
          }
          {userWhitelisted && !userWhiteClaimed && 
            <>
            <br/>
            <Form 
              data={{ 
                referralCode: {
                  label: `Referral Code`,
                  type: 'text',
                  value: shopReferrer,
                },
                mintAmount: {
                  label: `Number to Mint (Maximum 1)`,
                  type: 'number',
                  value: chosenMintQuantity,
                  min: 1,
                  max: 1,
                  required: true,
                  
                  errorMessage: `Please enter a number between 1 and ${projectInfo.data.project.max_mint}`
                },
              }}
              restrictWidth
              updateOnChange
              onChange={ e => {
                beTheChange(e)
              }}
              buttonText={ 'Whitelist Mint' }
              callback={ res => {
                //console.log(res);
                onMintWLPressed(res.mintAmount, res.referralCode);
              }}
            />
            </>
          }
          {projectInfo.data.project.crossmint_client_id && 1===3 && 
            <>
            <br/>
            <h1>No ETH? No Problem!</h1>
            <strong>Mint with Credit Card via our Crossmint.io integration.</strong><br/>After you mint, export to your wallet. It may be hidden, so check your hidden NFTs too.
            <CrossmintPayButton
              collectionTitle={projectInfo.data.project.project_name}
              collectionDescription={`${projectInfo.data.project.token_description}`}
              collectionPhoto={projectInfo.data.project.preview_image}
              clientId={projectInfo.data.project.crossmint_client_id}
              mintTo={account}
              mintConfig={{"type":"erc-721","totalPrice":`${chosenMintAmount}`,"_mintAmount":`${chosenMintQuantity}`}}
            />
            </>
          }
          </>
          
          }
        <span>{status}</span>
        
      </Card>

      
      </Row>
    </Animate>

    <Feedback />
    
    </div>
    </Fragment>
    
  );
}
